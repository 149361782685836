.profile{
    overflow-y: auto;
    height: 80vh;
    .box{
        border-radius: 10px;
        background-color: #fff;
        padding: 20px;
        // min-height: 75vh;
        .contain{
            border: 1px solid #e3e3e3;
            border-radius: 10px;
            .left{
                width: 100%;
                border-right: 1px solid #e3e3e3;
                padding: 20px;
                .box{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img{
                        margin-bottom: 20px;
                        width: 60%;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                    h2{
                        margin-bottom: 0;
                    }
                    h3{
                        margin-bottom: 40px;
                    }
                }
            }
            .right{
                width: 100%;
                padding: 20px;
                .box{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    h1{
                        margin-bottom: 0;
                    }
                    h2{
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }

}