.login{
    background-color: #EFF4FE;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: bottom 15% left, bottom left 50%;
    background-size: 50%, 85%;
    
    .box{
        padding: 50px 0;
        height: 100vh;

        .wrp-logo{
            text-align: left;
            .logo{
                object-fit: contain;
                height: 172px;
            }
            span {
                margin-left: 25px;
                color: #4167aed0;
            }
        }
    }
    .wrapform{
        // background-color: skyblue;
        height: 100%;
        display: flex;
        align-items: center;

        form{
            // width: 572px;
            padding: 48px 36px;
            background: #FFFFFF;
            box-shadow: 0px 15px 30px rgba(135, 155, 255, 0.15);
            border-radius: 20px;
            text-align: center;
            .title{
                margin-bottom: 48px;
                font-size: 18px;
            }
            .inp{
                width: 100%;
                margin-bottom: 36px;
                input{
                    padding-left: 8px;
                }
            }
            .remember {
                float: left;
            }
            button{
                width: 95px;
                height: 45px;
                background: #4168AE;
                border-radius: 5px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                text-transform: capitalize;
                color: white;
                float: right;
                margin-bottom: 36px;
                &:hover{
                    background: #4167aed0;
    
                }
            }
            .foot{
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                // margin-top: 36px;
                a{
                    color: #A0A0A0;
                }
            }
        }
    }
}