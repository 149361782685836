@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');


//GLOBAL STYLES

:root {
  .swal2-container {
    z-index: 3000;
  }
}

body {
  margin: 0 !important;
  /* overflow: auto; */

  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #303030;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  font-family: 'Poppins', sans-serif !important;
}

input {
  font-family: 'Poppins', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-template {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  background-color: #F4F6F9;
  z-index: -1;

  .conten {
    overflow: auto;
    min-height: 80vh;
    background-color: #F4F6F9;
    // overflow: hidden;
    position: relative;
    width: 100%;
  }
}

// overide modal fade react material ui
.modal_remove_outline {
  &:focus {
    outline: none;
  }
}

.custom_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000 !important;
}

.react-pdf__Page__svg {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;

  svg {
    width: 100% !important;
    height: 100% !important;
  }
}

button {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_custom {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 400000000 !important;
}

.image_preview {
  height: 100vh;
  width: 100vw;

  .header {
    display: flex;
    justify-content: end;
    background-color: #ffffffb5;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    height: 90vh;
  }
}

//remove autofill bg chrome
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.gmnoprint[role=menubar] {
  display: none;
}

.assign-fielding {
  position: relative;
  min-height: 70vh;
  overflow: auto;
  background-color: #F4F6F9;

  .container {
    min-width: 1700px;
  }
}