.csv-import {
    max-height: 90vh;
    max-width: 90vw;

    .option {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .label {
            width: 175px;
        }

        .MuiSelect-root {
            min-width: 100px;
        }
    }

    .table-container {

        overflow: auto;
        height: 50vh;

        table,
        th,
        td {
            border: 1px solid black;
            border-collapse: collapse;
        }
    }
}