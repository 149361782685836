.sidetab {
    position: absolute;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    li {
        cursor: pointer;
    }

    .tab-header-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .tab-header {
            margin-top: -40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #47748b;
            border-right: 1px solid whitesmoke;
            border-left: 1px solid whitesmoke;
            color: white;
            height: 40px;
            cursor: pointer;
            font-weight: bold;
            width: 115px;

            svg {
                width: 20px;
            }

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                white-space: nowrap;
            }

            &.active {
                background-color: #0885c4;
            }
        }
    }

    .tab-content-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .wraptab {
            z-index: 100;
            padding: 10px 10px 0px 10px;
            height: 85vh;
            overflow: auto;
            background-color: white;
            width: 300px;
            border-right: 1px solid whitesmoke;
            border-left: 1px solid whitesmoke;

            button {
                width: 100%;
                color: white;
                background-color: $success;
                margin-bottom: 10px;
                font-weight: bold;
                justify-content: flex-start;

                svg {
                    margin-right: 3px;
                    width: 18px;
                }
            }

            .MuiAutocomplete-popupIndicator {
                display: none;
            }

            .MuiAutocomplete-clearIndicator {
                display: none;
            }

            .need-reactive {
                background-color: lightgray;
                cursor: initial;
            }

            .title {
                // background-color: khaki;
                display: flex;
                justify-content: space-evenly;
                cursor: pointer;
                align-items: center;
                height: 40px;
                font-weight: bold;
                color: grey;
                border: 1px solid #e3e3e3;
                cursor: pointer;
                margin: 10px 0;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    text-transform: uppercase;
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                    // background-color: ;
                    border-radius: 6px;
                    height: 40px;
                    margin-bottom: 10px;
                    padding: 0 6px;
                    font-size: 14px;
                    color: #6C757D;

                    &:hover {
                        background-color: #cbced2;
                    }

                    span {
                        width: 100%;
                        cursor: pointer;

                        small {
                            width: fit-content;
                            text-transform: lowercase;
                            font-size: x-small;
                            color: orange;
                            vertical-align: top;
                            margin-left: 2px;
                        }
                    }
                }

                .listActive {
                    display: flex;
                    justify-content: space-between;
                    background-color: #cbced2;

                    .wrapshare {
                        display: flex;

                        .sharebut {
                            margin-left: 5px;
                            background-color: #fff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            cursor: pointer;

                            svg {
                                width: 20px;
                                padding: 0;
                                margin: 0;
                            }
                        }
                    }
                }

                .projnumberlist {
                    display: flex;
                    color: $success;
                    height: auto;
                    min-height: 40px;

                    .star {
                        color: #6C757D;
                        width: 23px;
                        cursor: pointer;
                        margin-right: 5px;
                    }

                    .customcheck {
                        padding: 0 !important;
                        margin-right: 10px;
                    }
                }

                .projnumberlist>span {
                    width: 230px;
                    overflow-wrap: break-word;
                }
            }

            .inp {
                margin-bottom: 10px;
                width: 100% !important;
            }

            .complete-Button {
                margin-top: 10px;
                background-color: $blue;
                justify-content: center;
            }

            .archive-Button {
                margin-top: 10px;
                background-color: $warning;
                justify-content: center;
            }

            .notes {
                display: flex;
                justify-content: space-between;
            }

            .fielding-toggle {
                display: flex;
                align-items: center;
                font-size: small;
                margin-left: 0px;
                border-bottom: 1px solid #6C757D;
            }
        }
    }

    .detailmenu {

        // min-width: 250px !important;
        // position: 
        .export-btn {
            justify-content: center;
            background-color: #ffc107;
            color: black;
        }

        .upload-btn {
            margin-top: 10px;
            background-color: #ffc107;
            width: 100%;
            font-weight: bold;
        }

        .notice {
            padding: 10px;
            background-color: blanchedalmond;
            font-style: italic;
        }

        .status-aerial {
            position: absolute;
            top: 0;
        }

        .note {
            .wrap-fil {
                padding: 10px;
                border-radius: 10px;
                background-color: whitesmoke;

                h4 {
                    margin-bottom: 0;
                }
            }

            .markicon {
                margin-top: 20px;
                background-color: aliceblue;
                padding: 10px;
                border-radius: 10px;

                h4 {
                    margin-bottom: 15px;
                    display: flex;
                    justify-content: space-between;
                }

                .wrapmark {
                    display: flex;
                    // justify-content: space-evenly;
                    align-items: center;

                    img {
                        cursor: pointer;
                        // background-color: #6C757D;
                        // border: 1px solid #e3e3e3;
                        object-fit: contain;
                        height: 25px;
                        width: 25px;
                        transition: all .1s;
                        margin-right: 10px;
                    }

                    .active {
                        transition: all .1s;
                        border: 3px solid red;
                        // border-radius: 6px;
                        height: 22px;
                        width: 22px;
                        // -webkit-box-shadow: 0px 0px 27px -2px rgba(250,15,15,1);
                        // -moz-box-shadow: 0px 0px 27px -2px rgba(250,15,15,1);
                        // box-shadow: 0px 0px 27px -2px rgba(250,15,15,1);
                    }
                }
            }
        }
    }
}

.formnote {
    background-color: white;
    width: 50vw;
    padding: 20px;
    max-height: 90vh;
    overflow: auto;

    h3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0px;

        svg {
            cursor: pointer;
        }
    }

    .creator {
        display: block;
        margin-bottom: 20px;
        font-size: small;
    }

    form {
        .inp {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}

.wrapbutton {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        background-color: grey !important;
        text-transform: capitalize !important;
        color: white;
        border: unset;
        margin-left: 10px !important;
    }

    :first-child {
        margin-left: 0px !important;
    }

    svg {
        width: 14px;
        margin: 0;
        padding: 0;
        margin-right: 5px;
    }

    .done {
        background-color: $blue !important;
    }

    .delete {
        background-color: $danger !important;
    }

    .edit {
        background-color: $warning !important;
    }

    .save {
        background-color: $warning !important;
    }

    .create {
        background-color: $blue !important;
    }

    .left {
        width: 100%;
    }
}

.note-list-container {
    padding: 20px;
}

.note-list {
    margin-top: 10px;

    h4 {
        margin-bottom: 7px;
    }

    .note-container {
        cursor: pointer;
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        background-color: rgb(255, 247, 185);
        width: 100%;

        .note-header {
            font-weight: bold;
            font-size: small;
            display: flex;
            justify-content: space-between;
        }

        .note-content {
            display: block;
            font-size: small;
        }
    }
}