.navbar{
    .topnav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 55px;
        padding: 0 35px;
        background-color: white;
        color: #7F7F7F;
        font-weight: 500;
        .title{
            font-size: 18px;
            display: flex ;
            align-items: center;
            a{
                color: black;
                text-decoration: none;
            }
            input{
                width: 150px;
                font-size: 14px;
            }
        }
        ul{
            .active {
                background-color: rgb(195, 195, 195);
            }
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            li{
                font-size: 14px;
                margin-left: 35px;
                list-style-type: none;
                position: relative;
                a{
                    text-decoration: none;
                    color: #7F7F7F;
                }
                .drop{
                    display: flex;
                    align-items: center;
                    svg{
                        width: 20px;
                    }
                }
                .list{
                    position: absolute;
                    z-index: 2000;
                    right: 0px;
                    margin-top: 10px;
                    border-radius: 10px;
                    border: 1px solid #e1e1e1;
                    overflow: hidden;
                    min-width: 150px;
                    hr{
                        border-top: 1px solid #e1e1e1;
                        border-bottom: unset;
                        margin: 0;
                    }
                    a{
                        color: $blue;

                    }
                    a,span{

                        text-decoration: none;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 0;
                        padding: 10px 10px;
                        background-color: white;
                        i{
                            display: flex;
                            align-items: center;
                            svg{
                                margin-right: 3px;
                            }
                        }
                    }
                    span{
                        svg{
                            width: 18px;
                        }
                    }
                    .logout{
                        cursor: pointer;
                        border-top: 1px solid #e1e1e1; ;
                        flex-direction: row;
                        color: black;
                    }
                }
            }
        }
    }
    .botnav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 55px;
        padding: 0 35px;
        background-color: white;
        color: #7F7F7F;
        font-weight: 500;
        .title{
            font-size: 14px;
        }
        ul{
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 300;
            li{
                margin-left: 10px;
                list-style-type: none;
                a{
                    font-size: 13px;
                    text-decoration: none;
                    color: rgb(50, 110, 241);
                }
            }
            .botmenu{
                cursor: pointer;
            }
        }
    }
}