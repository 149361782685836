.formproject {
    background-color: white;
    width: 500px;
    padding: 20px;
    overflow: auto;
    max-height: 90vh;

    h3 {
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
            cursor: pointer;
        }
    }

    form {
        .inp {
            width: 100%;
            margin-bottom: 20px;
        }

        .upload-btn {
            // margin-top: 10px;
            background-color: #ffc107;
            // width: 100%;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }

    .file-upload {
        background-color: rgb(208, 208, 208);
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 7px;

        .file-info {
            padding: 0px 5px 0px 5px;
            flex-grow: 1;
        }

        .file-progress {
            padding: 5px;
        }
    }

    .image-content {
        display: flex;
        flex-wrap: wrap;

        .image-container {
            display: inline;
            position: relative;
            margin-right: 5px;
            cursor: pointer;

            img {
                height: 80px;
                width: 150px;
                object-fit: contain;
                border: 1px solid #e3e3e3;
            }

            .document {
                height: 50px;
                width: 235px;
                object-fit: contain;
                border: 1px solid #e3e3e3;
                margin-bottom: 5px;
            }

            .bottom-left {
                position: absolute;
                bottom: 4px;
                left: 2px;
            }

            .bottom-right {
                position: absolute;
                bottom: 4px;
                right: 2px;
            }
        }
    }
}