.account{
    margin: 10px 0px;
    .action-button {
        display: flex;
        .button-add {
            color: white;
            background-color: $success;
        }
        .button-edit {
            color: white;
            margin-right: 10px;
            background-color: $warning;
        }
        .button-delete {
            background-color: $danger;
            color: white;
        }
    }
    .option-table {
        margin-top: 10px;
        width: 50%;
        .inp{
            width: 100%;
            margin-bottom: 7px;
            input{
                padding-left: 8px;
            }
        }
    }
}