//colors
$success : #28A745;
$blue : #007bff;
$danger : #dc3545;
$warning : #ffc107;
$info : #5bc0de;

@import './navbar.scss';
@import './sidetab.scss';
@import './dashboard.scss';
@import './formproject.scss';
@import './account.scss';
@import './formaccount.scss';
@import './layeractive.scss';
@import './pole-information.scss';
@import './spananddirpage.scss';
@import './assignfielding.scss';
@import './profile.scss';
@import './additionalpages.scss';
@import './csvImport.scss';

.align-right {
    display: flex;
    flex-direction: row-reverse;
}