.assignfielding{
    height: 80vh;
    overflow-y: auto;
    .box{
        background-color: white;
        padding: 20px;
    }
}

.assign_modal{
    background-color: white;
    width: 80vw;
    overflow-y: auto;
    .header{
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        background-color: white;
        position: sticky;
        top: 0;
        svg{
            cursor: pointer;
        }
    }
}

.map-modal{
    background-color: white;
    width: 80vw;
    height: 80vh;
    .header{
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        background-color: white;
        position: sticky;
        top: 0;
        svg{
            cursor: pointer;
        }
    }
}