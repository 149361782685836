.dashboard{
    position: relative;
    height: 80vh;
    width: 100%;
    // background-color:#e3e3e3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    // background-color: red;
    .wrapmap{
        width: 100%;
        height: 80vh;
    }
    .arrowleft{
        position: absolute;
        z-index: 1000;
        right: -20px;
    }
    .arrowright{
        position: absolute;
        z-index: 1000;
        left: -20px;
        .blink {
            animation: blink 1s linear infinite;
        }
        @keyframes blink{
            0%{opacity: 0;}
            50%{opacity: .5;}
            100%{opacity: 1;}
        }
    }

    .float-notification {
        position: absolute;
        right: 35%;
        top: 10px;
        width: 30vw;
        div {
            border-color: yellow;
            border-width: 3px;
            color: red;
            font-size: small;
        }
    }

    .map_button{
        position: absolute;
        right: 10px;
        width: 40px;
        height: 40px;
        background-color: whitesmoke;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        &:hover{
            // outline: 2px solid $blue;
            svg{
                color: $blue;
            }
        }
    }
    .move_pole{
        top: 70px;
    }
    .select_pole{
        top: 120px;
    }
    .measure{
        top: 170px;
    }
    .drag_select{
        top: 220px;
    }
    .clear_select{
        position: absolute;
        right: 10px;
        top: 220px;
    }
    .map_button_active{
        outline: 2px solid red;
    }
    .search-container {
        position: absolute;
        z-index: 100;
        right: 60px;
        top: 10px;
        width: 30%;
        .map_button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        input {
            box-sizing: border-box;
            border: 1px solid transparent;
            width: 100%;
            height: 40px;
            padding: 0 12px;
            border-radius: 3px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
            font-size: 14px;
            outline: none;
        }
        .input-coordinate {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 10px;
            input {
                width: 80%;
            }
            button {
                width: 15%;
                height: 40px;
                background-color: whitesmoke;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                border: none;
                &:hover{
                    svg{
                        color: $blue;
                    }
                }
            }
        }
    }
    .maptype-button {
        position: absolute;
        right: 60px;
        bottom: 23px;
        background-color: white;
        button.active {
            font-weight: bolder !important;
        }
    }

    .notification-button {
        position: absolute;
        right: 60px;
        bottom: 75px;
        background-color: white;
    }

    .notification-button:hover {
        background-color: white;
    }
}

.modal_selectedpole{
    background-color: #fff;
    padding: 20px;
    width: 500px;
    h3{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        button{
            background-color: $danger;
            color: white;
        }
    }
    form{
        .inp{
            width: 100%;
            margin-bottom: 40px;
        }
        // text-align: center;
        .wrap{
            display: flex;
            justify-content: flex-end;
            button{
                width: 100px;
                &:last-of-type{
                    background-color: $success;
                    margin-left: 15px;
                }
            }
        }
    }
}