    .box.spananddirpage {
        .wrapmap{
            margin-bottom: 20px;
            .header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-bottom: 10px;
                i{
                    color: red;
                }
                .bgroup{
                    .save{
                        color: white;
                        background-color: $success !important;
                    }
                    .undo{
                        background-color: $warning !important;
                        
                    }
                    .clear{
                        color: white;
                        background-color: $danger;
    
                    }
                }
                .addspan{
                    background-color: $blue;
                    color: white;
                }
            }
        }
        .inp{
            width: 100%;
            margin-bottom: 10px;
            button{
                background-color: $danger;
                color: white;
            }
        }
        .savebutton{
            margin-top: 10px;
            button{
                color: white;
                width: 100%;
                background-color: $success;
            }
        }
    }


