.pole-information {
    background-color: white;
    width: 100%;
    height: 80vh;
    float: right;
    z-index: 10000;
    top: 0;
    right: 0;

    input {
        &:disabled {
            color: black !important;
        }
    }

    div.Mui-disabled {
        color: black !important;
        cursor: not-allowed;
    }

    .boxform {
        padding-left: 25px;

        .header {
            padding-right: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: sticky;
            // width: 100%;
            flex-wrap: wrap;
            background-color: white;
            z-index: 2;

            .title {
                display: flex;
                align-items: center;

                h3 {
                    margin-bottom: 0;
                    text-transform: uppercase;
                    margin-right: 5px;
                }
            }

            border-bottom: 1px solid grey;
        }

        form {
            margin-top: 10px;
            overflow-y: auto;
            overflow-x: hidden;
            height: 70vh;
            padding-right: 10px;
        }

        .inp {
            width: 100%;
            // input{
            //     &:required{
            //         border: 1px solid red;
            //     }
            // }
        }

        button,
        .add {
            color: white;
            margin: 10px 0;
            display: flex;

            svg {
                width: 14px;
                margin: 0;
                padding: 0;
                margin-right: 5px;
            }
        }

        .add {
            background-color: $blue;
        }

        .view {
            color: black;
            background-color: $warning;
        }

        .header-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h4 {
                margin-bottom: 0;
                text-transform: uppercase;
            }
        }

        .dropzone-container {
            height: 50px;
            cursor: pointer;
            padding: 5px;
            border: 4px dashed $blue;
            margin-bottom: 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .table-form {
            text-align: center;

            thead {
                background-color: whitesmoke;

                tr {
                    th {
                        padding: 3px 0;
                        font-size: 14px !important;
                    }
                }
            }

            tbody {
                background-color: whitesmoke;

                tr {
                    td {
                        padding: 3px 0;
                        font-size: 14px !important;

                        .centered {
                            display: flex;
                            justify-content: center;
                        }

                        button {
                            margin: 0;
                            background-color: $danger;
                        }
                    }
                }
            }

        }

        .image-content {
            display: flex;
            flex-wrap: wrap;

            .image-container {
                display: inline;
                position: relative;

                img {
                    height: 80px;
                    width: 150px;
                    object-fit: contain;
                    border: 1px solid #e3e3e3;
                    margin-right: 5px;
                }
            }
        }

        .doc-content {
            display: flex;
            flex-wrap: wrap;

            .doc-container {
                position: relative;

                .doc {
                    height: 80px;
                    width: 150px;
                    border: 1px solid #e3e3e3;
                    margin-right: 5px;
                    margin-bottom: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    p {
                        text-align: center;
                        width: 100%;
                        font-size: .7em;
                        margin-bottom: 0;
                        word-wrap: break-word;
                    }
                }
            }
        }

        .attach-delete-icon {
            cursor: pointer;
            display: inline;
            position: absolute;
            right: 5px;
            bottom: 0px;
        }

        .attach-star-icon {
            cursor: pointer;
            display: inline;
            position: absolute;
            right: 5px;
        }

        .figsketch-small {
            margin-bottom: 19px;

            canvas {
                background-position: center;
                background-repeat: no-repeat;
                border: 1px solid grey;
            }
        }

        .figsketch-map {
            margin-bottom: 19px;
        }

    }

    .litle-checkbox {
        .MuiFormControlLabel-label {
            font-size: 0.75rem;
        }

        .PrivateSwitchBase-input-8 {
            height: 20px;
        }

        .MuiButtonBase-root {
            padding-top: 0px;
            padding-bottom: 0px;
        }

        margin-bottom: 10px;
    }
}

.modalsketchlib {
    background-color: white;
    width: 900px;
    height: 85vh;
    overflow-y: auto;

    h3 {
        padding: 20px;
        background-color: white;
        position: sticky;
        z-index: 1002;
        margin-bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
            cursor: pointer;
        }
    }

    .box {
        padding: 20px;
    }

    .figsketch {
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: red;
        margin-bottom: 20px;

        // .sketch{
        //     background-color: yellow;
        //     width: 400px;
        //     height: 250px;
        // }
        canvas {
            background-position: center;
            background-repeat: no-repeat;
            border: 1px solid grey;
        }
    }

    .inp {
        width: 100%;
        margin-bottom: 10px;
        // overflow: hidden !important;
        border-radius: 4px;
        border: none !important;

        input {
            border: none !important;

        }

        button {
            background-color: $danger;
            border: none;
            color: white
        }
    }

    .wrapbut {
        text-align: right;

        button {
            width: 100%;
            border: none;
            background-color: $success;
            color: white;
            // color: white
        }
    }

    .anchorbut {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        button {
            width: 150px;
            background-color: $blue;
            border: none;
            color: white
        }

        .cb {
            button {
                margin-left: 10px;
            }
        }
    }

    .anchorclear {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
            color: black;
            width: 150px;
            background-color: $warning;
            border: none;
            color: white;
            margin-left: 10px;
        }
    }

    .dynformbut {
        background-color: $blue;
        color: white;
        margin-bottom: 10px;

        svg {
            width: 18px;
            margin-right: 5px;
        }
    }

    .dynform {
        padding: 20px;
        background-color: whitesmoke;
        border-radius: 10px;

        .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            h4 {
                margin-bottom: 0;
            }

            svg {
                cursor: pointer;
                color: $danger;
                width: 20px;
                margin-right: 5px;
            }
        }
    }

    .risertype {
        padding: 10px;
        background-color: whitesmoke;

        button {
            background-color: $blue;
            width: 100%;
            text-transform: capitalize !important;
            border: unset;
            color: white;
        }
    }

    .litle-checkbox {
        .MuiFormControlLabel-label {
            font-size: 0.75rem;
        }

        .PrivateSwitchBase-input-8 {
            height: 20px;
        }

        .MuiButtonBase-root {
            padding-top: 0px;
            padding-bottom: 0px;
        }

        margin-bottom: 10px;
    }
}

.file-list-modal {
    padding: 10px;

    .upload-btn {
        margin-top: 10px;
        background-color: #ffc107;
        width: 100%;
        font-weight: bold;
    }

    .delete-header {
        display: flex;
        justify-content: space-between;
    }

    .supporting-list {
        margin-top: 20px;

        h4 {
            margin-bottom: 7px;
        }

        .supporting-container {
            font-size: small;
            margin-bottom: 5px;
            padding: 7px;
            border-radius: 10px;
            margin-bottom: 10px;
            background-color: rgb(255, 247, 185);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            a {
                overflow-wrap: break-word;
            }

            svg {
                cursor: pointer;
                min-width: 20px;
            }
        }
    }
}

.image-modal {
    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .option {
            display: flex;
            justify-content: right;

            button {
                margin-left: 5px;
            }
        }
    }

    .slider {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        img {
            height: 60vh;
            object-fit: contain;
            width: 80%;
        }

        span {
            margin-top: 30vh;
            margin-bottom: 30vh;
        }
    }

    .bordered-table {

        table,
        th,
        td {
            border: 1px solid black;
            border-collapse: collapse;
        }

        td {
            padding: 5px;
        }
    }

    .list-item {
        display: flex;
        flex-direction: row;

        .number {
            margin-right: 10px;
        }
    }
}

.image-editor-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .image-editor-modal-content {
        width: 90%;
        height: 90vh;
        padding: 10px;
        background-color: cornsilk;

        .hider {
            background-color: #1e1e1e;
            height: 50px;
            width: 90%;
            position: absolute;
            z-index: 1;
        }

        .footer {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .progress {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: 100%;
            }
        }
    }
}

.minimap-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 14px !important;
    width: 100%;

    .minimap {
        width: 100%;
        margin-right: 10px;
        display: flex;
        justify-content: center;
    }

    .information {
        width: 100%;
        display: flex;
        justify-content: center;

        table {
            width: 100%;
        }
    }
}