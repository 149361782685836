.layeractive{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 100%;
    left: 0;
    right: 0;
    background-color: white;
    height: 70px;
    position: absolute;
    padding: 0 25px;
    z-index: 100;
    -webkit-box-shadow: 1px 6px 17px 5px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 6px 17px 5px rgba(0,0,0,0.3);
    box-shadow: 1px 6px 17px 5px rgba(0,0,0,0.3);
    .buttons{
        button{
            margin-right: 20px;
            svg{
                width: 20px;
                margin-left: 10px;
            }
        }
        .info{
            background-color: $info;
            color: white;
        }

    }
    .mapicon{
        display: flex;
        align-items: center;
        font-weight: bold;
        // border: 1px solid #e3e3e3;
        height: 35px;
        padding: 0 10px;
        font-size: 14px;
        img{
            cursor: pointer;
            // background-color: #6C757D;
            // border: 1px solid #e3e3e3;
            object-fit: contain;
            height: 25px;
            width: 25px;
            transition: all .1s;
            margin-right: 10px;
            &:last-of-type{
                margin-right: 0;
            }
        }
        .active{
            transition: all .1s;
            border: 3px solid red;
            // border-radius: 6px;
            height: 22px;
            width: 22px;
            // -webkit-box-shadow: 0px 0px 27px -2px rgba(250,15,15,1);
            // -moz-box-shadow: 0px 0px 27px -2px rgba(250,15,15,1);
            // box-shadow: 0px 0px 27px -2px rgba(250,15,15,1);
        }
    }
    .pole-loading-button {
        display: flex;
        flex-direction: row;
        button{
            margin-left: 10px;
            color: white;
            background-color: $success;
            font-weight: bold;
            svg{
                margin-right: 3px;
                width: 18px;
            }
        }
        .active {
            border: 3px solid red;
        }
    }
    .done{
        color: white;
        background-color: $success;
    }
    .note-button {
        display: flex;
        align-items: center;
        .note-color-picker-container {
            span {
                margin-right: 3px;
            }
            display: flex;
            align-items: center;
            border: 1px solid gray;
            height: 29px;
            padding: 2px;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 5px;
        }
        .note-button-save {
            margin-left: 10px;
            background-color: green;
            color: white;
        }
        .note-button-undo {
            margin-left: 3px;
            background-color: blue;
            color: white;
        }
        .note-button-clear {
            margin-left: 3px;
            background-color: red;
            color: white;
        }
    }
}