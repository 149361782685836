.pole-table {
    margin-bottom: 10px;
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    td {
        padding: 5px;
    }
    tr {
        td:first-child {
            width: 20%;
        }
    }
    .image-container {
        img {
            margin-right: 5px;
            border: 1px solid gray;
            height: 100px;
        }
    }
}