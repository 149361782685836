.formaccount, .formcompany{
    background-color: white;
    width: 500px;
    padding: 20px;
    h3{
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg{
            cursor: pointer;
        }
    }
    form{
        .inp{
            width: 100%;
            margin-bottom: 20px;
            svg{
                cursor: pointer;
            }
        }
        .roles{
            margin-bottom: 20px;
        }
        .upload-btn{
            // margin-top: 10px;
            background-color: #ffc107;
            // width: 100%;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }
    .helper-text {
        color: red;
    }
}